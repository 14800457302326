import React from "react";
import { Img } from 'react-image';
import preloadImage from 'components/pages/gallery/images/preloader.jpg'

const OhzImage = ( props ) => {
    
    const { alt = [], style = [] } = props;
    
    let imageAlt = !Array.isArray( alt ) ? [ alt ] : alt;

    imageAlt = [
        'Ohz5',
        'Ohz',
        'Oosthuizen 5',
        'v.v. Oosthuizen 5',
        ...imageAlt
    ];

    return (
        <Img
            {...props}
            alt={ imageAlt.join(', ') }
            style={ style }
        />
    )
}

export const OhzImageLoader = ({ style = {} }) => {
    return (
        <OhzImage
            src={ preloadImage }
            style={ style }
        />
    )
}

export const OhzImageWithPreload = ({ src, alt = '', style = {} }) => {
    
    return (
        <OhzImage
            src={ [ src, src, src, preloadImage] }
            alt={ alt }
            style={ style }
            loader={ <OhzImageLoader style={ style } /> }
        />
    )
}

export default OhzImage
