import _object from 'lodash/object'
import hash from 'object-hash'

export default class ComponentKey {
	
	static create( prefix, object = {} ) {
		// When the object is a string, make it an object
		if( typeof object === 'string' ) {
			object = { guid: object };
		}
		
		// When the object is a string, make it an object
		if( typeof object === 'string' || typeof object === 'number' ) {
			object = { guid: object };
		}
		
		let key = _object.get( object, 'guid', false );
		
		if( !this.isValid( key ) ) {
			key = _object.get( object, 'id', false );
		}
		
		if( !this.isValid( key )) {
			key = _object.get( object, 'name', false );
		}
		
		if( !this.isValid( key ) ) {
			key = _object.get( object, 'title', false );
		}
		
		if( !this.isValid( key ) || key === false || typeof key === 'undefined' ) {
			key = hash( object );
		}
		
		key = String( key ).replace(/[^a-z0-9]/gi,'');
		
		return prefix +'-'+ String( key ).replace(/[^a-z0-9]/gi,'');
	}
	
	static isValid(o) {
		return typeof o !== 'undefined' && o !== null && o !== false && o !== 'false';
	};
}
