import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, useLocation } from "react-router-dom";
import client from 'library/graphql/client';
import ErrorBoundary from "components/ErrorBoundary";
import { ApolloProvider } from '@apollo/client';
import PageBase from 'components/pages'
import HelperDevelopment from 'library/development'
import * as Sentry from '@sentry/browser'

if( !HelperDevelopment.reactIsInDevelopmentMode() ) {
    Sentry.init( {
        dsn: "https://fe220271dd694f278b1de5fca797f7ea@o348655.ingest.sentry.io/2231559",
    } );
}

const App = () => {
    return (
        <ApolloProvider client={ client }>
            <ErrorBoundary>
                <BrowserRouter>
                    <PageBase />
                </BrowserRouter>
            </ErrorBoundary>
        </ApolloProvider>
    );
}

export default App;
