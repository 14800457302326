import React from "react";

const LoadingComponent = () => {
    
    return (
        <div id="preloader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default LoadingComponent
