import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'raf/polyfill';
import ReactDOM from "react-dom";
import React from "react";
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/weak-map';
import App from "./components/App";
import './App.scss'
import './styles/style.scss'
import './styles/libraries/font-awesome/scss/fontawesome.scss';
import './styles/libraries/font-awesome/scss/solid.scss';
import './styles/libraries/font-awesome/scss/brands.scss';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/App');

//
if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}
