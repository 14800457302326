import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import _collection from 'lodash/collection';
import _object from 'lodash/object';
import _array from 'lodash/array'
import { routePaths } from 'routes'
import ComponentKey from 'library/ComponentKey'

const Menu = () => {
    
    const [ navExpanded, setNavExpanded ] = useState( false )
    
    const items = [
        {
            to: '/',
            name: 'Home'
        },
        {
            to: _array.last( routePaths.NEWS ),
            name: 'Nieuws',
        },
        {
            to: _array.last( routePaths.RANKINGS ),
            name: 'Stand',
        },
        {
            to: _array.last( routePaths.RESULTS ),
            name: 'Uitslagen',
        },
        {
            name: 'Spelers',
            sub: [
                {
                    to: _array.last( routePaths.PLAYERS ),
                    name: 'Overzicht'
                },
                {
                    to: _array.last( routePaths.PLAYER_GOALS ),
                    name: 'Doelpunten',
                },
                {
                    to: _array.last( routePaths.PLAYER_TRAINING ),
                    name: 'Trainingen',
                },
            ]
        },
        {
            to: _array.last( routePaths.GIPHY ),
            name: 'Gifjes',
        },
        {
            to: _array.last( routePaths.GALLERIES ),
            name: 'Foto\'s',
        },
        {
            to: _array.last( routePaths.VIDEOS ),
            name: 'Videos',
        },
    ];

    const linkProps = {
        onClick: setNavExpanded.bind( this, false ),
    };

    return (
        <div className={'main-menu'}>
            <Navbar collapseOnSelect expand="md" variant="dark" expanded={navExpanded} onToggle={ setNavExpanded.bind( this ) }>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        { _collection.map( items, (item) => {
                            const to = _object.get( item, 'to', '' );
                            const name = _object.get( item, 'name', '' );
                            const sub = _object.get( item, 'sub', null );
                            const props = _object.get( item, 'props', {} );
                            
                            if( sub !== null ) {
                                return (
                                    <NavDropdown
                                        key={ ComponentKey.create( 'dropdown', name ) }
                                        title={name}>
                                        {_collection.map( sub, ( subItem ) => {
                                            const to = _object.get( subItem, 'to', '' );
                                            const name = _object.get( subItem, 'name', '' );
                                            const props = _object.get( subItem, 'props', {} );
                                            
                                            return (
                                                <NavDropdown.Item
                                                    key={ ComponentKey.create( 'dropdown-'+name, to ) }>
                                                    
                                                    <NavLink
                                                        {...linkProps}
                                                        {...props}
                                                        to={ to }>
                                                        
                                                        { name }
                                                    </NavLink>
                                                </NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                )
                            }
                            
                            return (
                                <NavLink
                                    key={ ComponentKey.create( 'nav-link', to ) }
                                    {...linkProps}
                                    {...props}
                                    className={'nav-link'}
                                    to={ to }>
                                    
                                    { name }
                                </NavLink>
                            )
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu
