import { gql } from "@apollo/client";

export default gql(`
    query app {
        page {
            sponsors( width: 350, height: 120 ) {
                name
                url
                image
            }
        }
        newsItems( page: 1, limit: 5 ) {
            id
            title
            date_pretty
            image_dynamic( width: 70, height: 65 )
            path
        }
    }
`)
