import { ApolloClient, InMemoryCache } from '@apollo/client'
import HelperDevelopment from 'library/development'

const clientUri = HelperDevelopment.reactIsInDevelopmentMode() ? 'http://ohz5.local/graphql' : 'https://server.ohz5.nl/graphql';
//const clientUri = 'https://ohz5.nl/graphql';

const client = new ApolloClient({
    uri: clientUri,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        mutate: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore'
        }
    }
});

export default client
