import { useQuery } from '@apollo/client'
import GraphqlApp from 'components/gql/GraphqlApp'
import _object from 'lodash/object'
import routes from 'routes'
import { Link, Route, Routes } from 'react-router-dom'
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import OhzImage from 'library/image'
import logo from 'images/logo.png'
import Menu from 'components/menu'
import _collection from 'lodash/collection'
import ComponentKey from 'library/ComponentKey'
import ScrollToTop from 'components/view/scrolltotop'
import SponsorBannerImage from 'images/sponsor-banner.jpeg'
import _lang from 'lodash/lang'
import _string from 'lodash/string'
import LoadingComponent from 'components/view/loading'

const PageBase = () => {
	
	const { data } = useQuery( GraphqlApp );
	
	const page = _object.get( data, ['page'], {} );
	const newsItems = _object.get( data, ['newsItems'], [] );
	
	const urls = {
		'programma': 'Programma',
		'nieuws': 'Nieuws',
		'poulestand': 'Stand',
		'uitslagen': 'Uitslagen',
		'spelers': 'Spelers',
		'fotos': 'Foto\'s',
		'videos': 'Videos',
	};
	
	const createRoute = ( path, element, props = {} ) => {
		return (
			<Route
				key={ path }
				path={ path }
				element={ element }
				{ ...props }
			/>
		)
	}
	
	const routeElements = [];
	_collection.forEach( routes, ( route, idx ) => {
		const paths = _lang.isArray( route.path ) ? route.path : [ route.path ]
		
		_collection.forEach( paths, path => {
			if( !route.exact ) {
				routeElements.push( createRoute( path, route.component ) )
				
				if( !_string.endsWith( path, '/' ) ) {
					path += '/'
				}
				
				path += '*'
			}
			
			let params = {}
			if( path === '' ) {
				params.index = true
			}
			
			routeElements.push( createRoute( path, route.component, params ) )
		})
	});
	
	const sponsors = _object.get( page, 'sponsors', [] );
	
	return (
		<ScrollToTop>
			<Helmet
				defaultTitle="V.V. Oosthuizen 5"
				titleTemplate="%s - V.V. Oosthuizen 5">
				
				<meta name="description" content="Niet alleen het leukste maar ook nog het gezelligste voetbalteam van Nederland!" />
			</Helmet>
			
			<header>
				<div className="header-top-area">
					<Container>
						<Row>
							<Col lg={8} md={8} sm={8} xs={9}>
								<div className="header-top-left">
									<ul>
										<li>V.V. Oosthuizen 5 het leukste en gezelligste team van Nederland!</li>
									</ul>
								</div>
							</Col>
							<Col lg={4} md={4} sm={4} xs={3}>
								<div className="social-media-area">
									<nav>
										<ul>
											<li>
												<a href="https://www.facebook.com/VVoosthuizen5/" target="_blank">
													<i className="fa fab fa-facebook-f"/>
												</a>
											</li>
											<li>
												<a href="https://www.instagram.com/vvoosthuizen_5/" target="_blank">
													<i className="fa fab fa-instagram"/>
												</a>
											</li>
										</ul>
									</nav>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className={'header-middle-area menu-sticky'}>
					<Container>
						<Row>
							<Col md={2} sm={12} xs={12} className={'logo'}>
								<Link to={"/"}>
									<OhzImage src={logo} alt={'Logo Oosthuizen 5'} />
								</Link>
							</Col>
							<Col md={10} sm={12} xs={12} className={'mobile-menu'}>
								<Menu />
							</Col>
						</Row>
					</Container>
				</div>
			</header>
			
			<div className="justify-content-center">
				<Suspense fallback={ <LoadingComponent/> }>
					<Routes>
						{ routeElements }
					</Routes>
				</Suspense>
				
				<div className={'client-logo-section'} style={{ backgroundImage: `url(${ SponsorBannerImage })` }}>
					<div className={'overlay-bg sec-spacer'}>
						<Container>
							{ sponsors.length > 0 ? (
								<Row>
									{ _collection.map( sponsors, (sponsor) => (
										<Col
											key={ ComponentKey.create( 'sponsor-col', sponsor )}
											lg={6}
											sm={12}>
											
											<div className={'single-logo'} style={{ padding: 0 }}>
												<a href={ sponsor.url }>
													<OhzImage src={ sponsor.image } alt={ sponsor.name } />
												</a>
											</div>
										</Col>
									))}
								</Row>
							) : null }
						</Container>
					</div>
				</div>
				
				<div className={'footer-section'}>
					<div className={'footer-top'}>
						<Container>
							<Row>
								<Col md={3} xs={12}>
									<h4 className={'footer-title'}>Over ons</h4>
									<div className={'about-widget'}>
										<p>
											V.V. Oosthuizen 5 (Ohz 5) is niet alleen het leukste maar ook nog het gezelligste voetbalteam van Nederland!
										</p>
									</div>
								</Col>
								<Col md={6} xs={12}>
									<h4 className={'footer-title'}>Recent Nieuws</h4>
									<div className={'recent-post-widget'}>
										{ _collection.map( newsItems, (newsItem) => (
											<div
												key={ ComponentKey.create( 'recent-post-widget', newsItem )}
												className={'post-item'}>
												<div className={'post-date'}>
													<OhzImage src={ _object.get( newsItem, 'image_dynamic', null ) } alt={ _object.get( newsItem, 'title', null ) } />
												</div>
												<div className={'post-desc'}>
													<h3 className={'post-title text-white'}>
														<Link to={'/nieuws/'+ _object.get( newsItem, 'path', null ) }>
															{ _object.get( newsItem, 'title', null ) }
														</Link>
														<br />
														<span className={'date'}>
                                                            <i className={'fa fa-calendar'} aria-hidden={'true'} />
															{ _object.get( newsItem, 'date_pretty', '' ) }
                                                        </span>
													</h3>
												</div>
											</div>
										))}
									</div>
								</Col>
								<Col md={3} xs={12}>
									<h4 className={'footer-title'}>Handige Linkjes</h4>
									<ul className={'sitemap-widget'}>
										{ _collection.map( urls, (name, url) => (
											<li
												key={ ComponentKey.create( 'sitemap-widget', url ) }>
												<Link to={'/'+ url}>
													{name}
												</Link>
											</li>
										))}
									</ul>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				
				<div className={'footer-bottom'}>
					<Container>
						<Row>
							<Col md={4} sm={6}>
								<div className={'copyright'}>
									<p>&copy; {(new Date().getFullYear())} <a href="" target="_blank">Oosthuizen 5</a>. All Rights Reserved.</p>
								</div>
							</Col>
							<Col md={4} sm={6}>
								<div className={'copyright'}>
									<p>Website van <a href="https://mmaaikel.nl" target="_blank">Maikel Reijnders</a>.</p>
								</div>
							</Col>
							<Col md={4} sm={12}>
								<div className={'text-right ft-bottom-right'}>
									<div className={'footer-bottom-share'}>
										<ul>
											<li>
												<a href="https://www.facebook.com/VVoosthuizen5/" target="_blank">
													<i className="fa fab fa-facebook"/>
												</a>
											</li>
											<li>
												<a href="https://instagram.com/vvoosthuizen_5/" target="_blank">
													<i className="fa fab fa-instagram"/>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</ScrollToTop>
	)
}

export default PageBase
