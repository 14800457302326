import { lazy } from 'react'

const PageHome = lazy(() => import('components/pages/home'));
const PageProgram = lazy(() => import('components/pages/program'));
const PageNews = lazy(() => import('components/pages/news'));
const PageNewsView = lazy(() => import('components/pages/news/view'));
const PagePlayers = lazy(() => import('components/pages/players'));
const PagePlayersView = lazy(() => import('components/pages/players/view'));
const PagePlayersGoals = lazy(() => import('components/pages/players/goals'));
const PagePlayersTraining = lazy(() => import('components/pages/players/trainings'));
const PageGallery = lazy(() => import('components/pages/gallery'));
const PageGalleryView = lazy(() => import('components/pages/gallery/view'));
const PageRakingIndex = lazy(() => import('components/pages/ranking'));
const PageResults = lazy(() => import('components/pages/results'));
const PageVideo = lazy(() => import('components/pages/video'));
const PageVideoView = lazy(() => import('components/pages/video/view'));
const PageGiphy = lazy(() => import('components/pages/gifphy'));

export const routePaths = {
    ABOUT: [
        '/over',
        '/about'
    ],
    PROGRAM: [ '/programma' ],
    NEWS: [ '/nieuws' ],
    NEWS_VIEW: [ '/nieuws/:id' ],
    PLAYERS: [
        '/spelers/seizoen/:season_id/:year',
        '/spelers/seizoen/:season_id',
        '/spelers',
    ],
    PLAYER_VIEW: [ '/spelers/:player_id' ],
    PLAYER_GOALS: [
        '/spelers/doelpunten/seizoen/:season_id/:year',
        '/spelers/doelpunten/seizoen/:season_id',
        '/spelers/doelpunten',
    ],
    PLAYER_TRAINING: [
        '/spelers/trainingen/seizoen/:season_id/:year',
        '/spelers/trainingen/seizoen/:season_id',
        '/spelers/trainingen',
    ],
    GALLERIES: [ '/fotos' ],
    GALLERY_VIEW: [ '/fotos/:id' ],
    RANKINGS: [
        '/poulestand/seizoen/:season_id',
        '/poulestand',
    ],
    RESULTS: [
        '/uitslagen/seizoen/:season_id',
        '/uitslagen',
    ],
    VIDEOS: [ '/videos' ],
    VIDEO_VIEW: [ '/videos/:id' ],
    GIPHY: [ '/giphy' ]
}

const routes = [
    { path: routePaths.ABOUT, component: <PageHome />, exact: true },
    { path: routePaths.PROGRAM, component: <PageProgram />, exact: false },
    
    { path: routePaths.NEWS, component: <PageNews />, exact: true },
    { path: routePaths.NEWS_VIEW, component: <PageNewsView />, exact: false },
    
    { path: routePaths.PLAYERS, component: <PagePlayers />, exact: true },
    { path: routePaths.PLAYER_GOALS, component: <PagePlayersGoals />, exact: true },
    { path: routePaths.PLAYER_TRAINING, component: <PagePlayersTraining />, exact: true },
    { path: routePaths.PLAYER_VIEW, component: <PagePlayersView />, exact: false },
    
    { path: routePaths.GALLERIES, component: <PageGallery />, exact: true },
    { path: routePaths.GALLERY_VIEW, component: <PageGalleryView />, exact: false },
    
    { path: routePaths.RANKINGS, component: <PageRakingIndex />, exact: false },
    
    { path: routePaths.RESULTS, component: <PageResults />, exact: false },
    
    { path: routePaths.VIDEOS, component: <PageVideo />, exact: true },
    { path: routePaths.VIDEO_VIEW, component: <PageVideoView />, exact: false },
    
    { path: routePaths.GIPHY, component: <PageGiphy />, exact: true },
    
    { path: [ '/' ], component: <PageHome />, exact: false },
];

export default routes;
